import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  image?: ImageProps
  services: ServiceProps[]
  title?: string
}

export const VisualServices = memo(function VisualServices({
  image,
  services,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
        {title ? <Title>{title}</Title> : null}
      </Wrapper>

      <Services row wrap>
        {services.map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
    </Container>
  )
})

const Container = styled.section`
  margin: 9rem auto 10.8125rem;
  overflow: hidden;
  position: relative;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 4.5rem auto 5.3125rem;
  }

  @media (max-width: 767px) {
    margin: 3.75rem auto 0;
  }
`

const Wrapper = styled.div`
  padding: 11.125rem 0 5rem;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: calc(100% + 7.5rem);
    backface-visibility: hidden;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img {
    width: 100%;
    height: calc(100% + 7.5rem);
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    padding: 7.5rem 1.25rem 3rem;
  }
`

const Title = styled.h2`
  max-width: 31.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.375rem;
  margin: auto;
  position: relative;
  z-index: 2;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const Services = styled(FlexBox)`
  max-width: 59.722vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: auto;
  padding: 1.75rem 8.958vw 5.625rem;
  position: relative;
  z-index: 2;

  @media (max-width: 1339px) {
    width: calc(100% - 10vw);
    max-width: none;
  }

  @media (max-width: 1199px) {
    padding: 1.75rem 4vw 1.875rem;
  }

  @media (max-width: 1023px) {
    width: calc(100% - 2.5rem);
    padding: 1.75rem 1.875rem 0;
  }

  @media (max-width: 767px) {
    width: calc(100% - 1.875rem);
    padding: 0.5rem 0 0;
  }
`
